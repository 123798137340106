import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  headline: ({ classes }) => ({
    color: theme.palette.primary.light,
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamilySecondary,
  }),
}));

const PageTitle = props => {
  const { children, ...passProps } = props;
  const classes = useStyles();
  return (
    <Typography
      className={classes.headline}
      variant="h2"
      component="h1"
      {...passProps}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
