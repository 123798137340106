import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import PageHeader from 'src/components/content/PageHeader';
import PageTitle from 'src/components/content/PageTitle';
import MarkdownSectionsContainer from 'src/components/markdown/MarkdownSectionsContainer';
import { useLocalization } from 'src/providers/LocalizationProvider';

const DefaultPage = ({ pageContext }) => {
  const { title } = pageContext;
  const { page } = useLocalization();

  return (
    <>
      <PageHeader />
      <Container maxWidth="lg">
        <Box pt={16} pb={8} textAlign="center">
          <PageTitle>{title}</PageTitle>
        </Box>
      </Container>
      <Box pb={[4, 8, 16]}>
        <MarkdownSectionsContainer page={page} />
      </Box>
    </>
  );
};

export default DefaultPage;
