import React from 'react';
import MarkdownSection from './MarkdownSection';
import { useMarkdown } from 'src/providers/MarkdownProvider';

const MarkdownSectionRef = React.forwardRef((props, ref) => (
  <MarkdownSection {...props} innerRef={ref} />
));

export default function MarkdownSectionsContainer({ page }) {
  const { getSectionsByPage } = useMarkdown();
  const sections = getSectionsByPage(page);

  return (
    <>
      {sections.map((mdx, idx) => (
        <MarkdownSectionRef key={mdx.id} index={idx} {...mdx} />
      ))}
    </>
  );
}
